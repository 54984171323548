import { msg, t } from '@lingui/macro';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'actions/utils';

import { pushSuccessToaster } from 'components/ui/Toaster';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

export const READER_TYPE = 'reader';
export const CONTRIBUTOR_TYPE = 'contributor';
export const ADMIN_TYPE = 'admin';

export const CAMPAIGN_PERMISSION_OPTIONS = [
  {
    type: READER_TYPE,
    header: msg({ id: 'reader' }),
    help: msg({ id: 'campaign-reader-permission-help' }),
  },
  {
    type: CONTRIBUTOR_TYPE,
    header: msg({ id: 'contributor' }),
    help: msg({ id: 'campaign-contributor-permission-help' }),
  },
  {
    type: ADMIN_TYPE,
    header: msg({ id: 'admin' }),
    help: msg({ id: 'campaign-admin-permission-help' }),
  },
];

export const getPermissionType = ({ read, write, admin }) =>
  (admin && ADMIN_TYPE) ||
  (write && CONTRIBUTOR_TYPE) ||
  (read && READER_TYPE) ||
  null;

const permissionFromType = (type) => ({
  // read is set to all types because it is the minimum permission
  read: [READER_TYPE, CONTRIBUTOR_TYPE, ADMIN_TYPE].includes(type),
  // write is set to contributor and admin because they are the only ones that can write
  write: [CONTRIBUTOR_TYPE, ADMIN_TYPE].includes(type),
  admin: type === ADMIN_TYPE,
});

export const addPermissionsToMembers = createAsyncThunk(
  'channels/addPermissionsToMembers:load',
  async ({ campaignId, userIds, permissionType }) => {
    const response = await api.post(
      `/campaign/${campaignId}/permission`,
      userIds.map((userId) => ({
        user: { id: userId },
        ...permissionFromType(permissionType),
        campaign: { id: campaignId },
      }))
    );
    pushSuccessToaster(t({ id: 'permissions-granted-success' }));
    return { campaignId, permissions: response?.data };
  }
);

export const updateMemberPermission = createAsyncThunk(
  'channels/updateMemberPermission:load',
  async ({ permission, newPermissionType }) => {
    const response = await api.put(
      `/campaign/${permission.campaign.id}/permission/${permission.id}`,
      {
        ...permission,
        ...permissionFromType(newPermissionType),
      }
    );
    pushSuccessToaster(t({ id: 'permissions-updated-success' }));
    return {
      campaignId: permission.campaign.id,
      userId: permission.user.id,
      permission: response.data,
    };
  }
);

export const maybeFetchCampaignPermissions = createAsyncThunk(
  'campaignPreset/maybeFetchCampaignPermissions:load',
  async ({ campaignId }, { getState }) => {
    // Do not fetch if already loaded
    const { permissions } = getState().campaignPermissions;
    if (permissions[campaignId]) {
      return {};
    }
    const response = await api.get(`/campaign/${campaignId}/permission`);
    return { campaignId, permissions: response.data };
  }
);

export const deleteCampaignPermissions = createAsyncThunk(
  'channels/deleteCampaignPermissions:load',
  async ({ campaignId, permissionId }) => {
    await api.delete(`/campaign/${campaignId}/permission/${permissionId}`);
    pushSuccessToaster(t({ id: 'permission-revoked' }));
    return {
      campaignId,
      permissionId,
    };
  }
);

const campaignPermissionsSlice = createAPISlice(
  {
    name: 'campaignPermissions',
    initialState: {
      permissions: {},
    },
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(
        maybeFetchCampaignPermissions.fulfilled,
        (state, { payload: { campaignId, permissions } }) => {
          state.permissions[campaignId] = permissions;
        }
      );
      builder.addCase(
        addPermissionsToMembers.fulfilled,
        (state, { payload: { campaignId, permissions } }) => {
          state.permissions[campaignId] = [
            ...state.permissions[campaignId],
            ...permissions,
          ];
        }
      );
      builder.addCase(
        updateMemberPermission.fulfilled,
        (state, { payload: { campaignId, permission } }) => {
          state.permissions[campaignId] = state.permissions[campaignId].map(
            (p) => (p.id === permission.id ? permission : p)
          );
        }
      );
      builder.addCase(
        deleteCampaignPermissions.fulfilled,
        (state, { payload: { campaignId, permissionId } }) => {
          state.permissions[campaignId] = state.permissions[campaignId].filter(
            (p) => p.id !== permissionId
          );
        }
      );
    },
  },
  {
    keys: [
      'maybeFetchCampaignPermissions',
      'addPermissionsToMembers',
      'updateMemberPermission',
    ],
  }
);

export default campaignPermissionsSlice.reducer;

export const createPermissionLoadingSelector = (state) =>
  state.campaignPermissions.addPermissionsToMembers === StateStatus.PENDING;
export const deletePermissionLoadingSelector = (state) =>
  state.campaignPermissions.deleteCampaignPermissions === StateStatus.PENDING;

export const campaignPermissionsSelectorFactory = (campaignId) => (state) =>
  state.campaignPermissions.permissions[campaignId];
