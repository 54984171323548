import { t } from '@lingui/macro';

import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { api } from 'actions/utils';
import { surveyQuestionsSelector } from 'selectors/survey';

import { pushDangerToaster, pushSuccessToaster } from 'components/ui/Toaster';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

export const defaultItemsPerPage = 20;
export const defaultPage = 1;
export const defaultSortOrder = -1;
export const defaultSortField = 'create_date';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels:load',
  ({
    campaignId,
    page = defaultPage,
    itemsPerPage = defaultItemsPerPage,
    sortField = defaultSortField,
    sortOrder = defaultSortOrder,
    searchQuery = '',
    showArchived = true,
  }) =>
    api
      .get(`/campaign/${campaignId}/channel`, {
        params: {
          page,
          itemsPerPage,
          sortField,
          sortOrder,
          searchQuery,
          showArchived,
        },
      })
      .then((response) => ({
        campaignId,
        channels: response.data.channels,
        count: response.data.total_count,
        isFullList:
          response.data.channels?.length === response.data.total_count,
      }))
);

export const createChannel = createAsyncThunk(
  'channels/createChannel:load',
  async ({ campaignId, channelName }, { getState }) => {
    const payload = {
      campaign: { id: campaignId },
      url_values: [],
      name: channelName,
    };
    Object.entries(getState().campaignChannels.create).forEach(
      ([formFieldId, value]) => {
        if (!value) return;
        if (value.tagSetId) {
          if (value.tagId) {
            payload.url_values.push({
              form_element: {
                id: formFieldId,
                form_type: 'MultipleChoiceFormElement',
              },
              tag_set: { id: value.tagSetId },
              tag: { id: value.tagId },
            });
          }
        } else if (formFieldId === 'concept') {
          payload.concept = { id: value };
        } else if (formFieldId === 'hiddenQuestions') {
          if (value?.length) {
            payload.hidden_questions = value.map((id) => ({ id }));
          }
        } else {
          payload.url_values.push({
            form_element: { id: formFieldId, form_type: 'InputFormElement' },
            value,
          });
        }
      }
    );
    const response = await api.post(`/campaign/${campaignId}/channel`, payload);
    pushSuccessToaster(t({ id: "channel-creation-success-title" }));
    return { campaignId, channel: response.data };
  }
);

export const deleteChannel = createAsyncThunk(
  'channels/deleteChannel:load',
  async ({ campaignId, channelId }) => {
    await api.delete(`/campaign/${campaignId}/channel/${channelId}`);
    return { campaignId, channelId };
  }
);

export const archiveChannel = createAsyncThunk(
  'channels/archiveChannel:load',
  async ({ campaignId, channel, archivalStatus }, { rejectWithValue }) => {
    try {
      await api.put(`/campaign/${campaignId}/channel/${channel.id}/archived`, {
        archived: archivalStatus,
      });
      pushSuccessToaster(
        !channel.archived
          ? t({ id: "channel-archive-success-title" })
          : t({ id: "channel-unarchive-success-title" })
      );
      return { campaignId, channelId: channel.id };
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      pushDangerToaster(
        !channel.archived
          ? t({ id: "channel-archive-error-message" })
          : t({ id: "channel-unarchive-error-message" })
      );
      return rejectWithValue({
        error: errorMessage,
        campaignId,
        channelId: channel.id,
      });
    }
  }
);
const channelsSlice = createAPISlice(
  {
    name: 'channels',
    initialState: {
      create: {},
      campaignIdChannels: {},
      campaignIdCounts: {},
      focusChannelId: null,
      count: 0,
    },
    reducers: {
      focusOnNextChannel(state, { payload: { campaignId, selectNext } }) {
        const channels = state.campaignIdChannels[campaignId] || [];
        if (!channels || channels.length === 0) {
          return;
        }
        const currentChannelIndex = channels.findIndex(
          ({ id }) => id === state.focusChannelId
        );
        if (currentChannelIndex === -1) {
          return;
        }
        // If payload select next, select previous otherwise
        let nextIndex = 0;
        if (selectNext) {
          nextIndex =
            currentChannelIndex + 1 <= channels.length - 1
              ? currentChannelIndex + 1
              : 0;
        } else {
          nextIndex =
            currentChannelIndex - 1 >= 0
              ? currentChannelIndex - 1
              : channels.length - 1;
        }
        state.focusChannelId = channels[nextIndex].id;
      },
      setFocusChannelId(state, { payload }) {
        state.focusChannelId = payload;
      },
      setChannelConceptValue(state, { payload }) {
        state.create.concept = payload;
      },
      setChannelFormValue(state, { payload: { key, value } }) {
        state.create[key] = value;
      },
      setChannelHiddenQuestions(state, { payload }) {
        state.create.hiddenQuestions = payload;
      },
      resetCreateFormValues(state) {
        state.create = {};
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        fetchChannels.fulfilled,
        (state, { payload: { campaignId, channels, count } }) => {
          state.campaignIdChannels[campaignId] = channels;
          state.campaignIdCounts[campaignId] = count;
        }
      );
    },
  },
  {
    keys: ['fetchChannels', 'createChannel', 'deleteChannel', 'archiveChannel'],
  }
);

export const {
  focusOnNextChannel,
  setFocusChannelId,
  setChannelFormValue,
  setChannelHiddenQuestions,
  setChannelConceptValue,
  resetCreateFormValues,
} = channelsSlice.actions;

export default channelsSlice.reducer;

export const campaignChannelsSelector = (campaignId) => (state) =>
  state.campaignChannels.campaignIdChannels[campaignId];
export const focusChannelSelector = (campaignId) => (state) =>
  state.campaignChannels.focusChannelId
    ? state.campaignChannels.campaignIdChannels[campaignId]?.find(
        ({ id }) => id === state.campaignChannels.focusChannelId
      )
    : null;
export const loadingChannelsSelector = (state) =>
  state.campaignChannels.fetchChannels === StateStatus.PENDING;

export const createChannelLoadingSelector = (state) =>
  state.campaignChannels.createChannel === StateStatus.PENDING;

export const createChannelFormValuesSelector = (state) =>
  state.campaignChannels.create;

export const campaignChannelsCountSelector = (campaignId) => (state) =>
  state.campaignChannels.campaignIdCounts[campaignId];

export const channelHiddenQuestionsSelector = createSelector(
  createChannelFormValuesSelector,
  (values) => values.hiddenQuestions || []
);

export const isChannelHiddenQuestionValidSelectorFactory = (campaignId) =>
  createSelector(
    surveyQuestionsSelector(campaignId, false),
    channelHiddenQuestionsSelector,
    (questions, hiddenQuestions) =>
      !hiddenQuestions?.length || hiddenQuestions?.length < questions?.length
  );
